import { ClassifactMobileModule } from '@tcc-mono/classifact/mobile/shell/feature';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { getAppConfigProvider } from '@tcc-mono/shared/app-config';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { TranslocoRootModule } from './transloco-root.module';
import { HttpClientModule } from '@angular/common/http';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    IonicModule.forRoot({
      swipeBackEnabled: false
    }),
    ClassifactMobileModule,
    HttpClientModule,
    TranslocoRootModule],
  providers: [
    getAppConfigProvider(environment),
    ScreenOrientation,
    InAppBrowser
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
