import { Router } from '@angular/router';
import { Component, NgZone } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'tcc-mono-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private router: Router,
    private zone: NgZone,
    screenOrientation: ScreenOrientation,
    platform: Platform
  ) {
    if (Capacitor.isNativePlatform()) {
      App.removeAllListeners().then(() => {
        this.initializeApp();
      });

      if (platform.is('capacitor')) {
        platform.ready().then(() => {
          screenOrientation.lock(screenOrientation.ORIENTATIONS.PORTRAIT);
        })
      }
    }
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split("tccapps.nl").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }
}
