import { TRANSLOCO_CONFIG, translocoConfig, TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { LocalstorageKey } from '@tcc-mono/shared/ui/theming';

import { availableLangs, translocoLoader } from '@tcc-mono/shared/utils'

@NgModule({
  imports: [
    TranslocoLocaleModule.forRoot({
      langToLocaleMapping: {
        en: 'en-GB',
        nl: 'nl-NL'
      }
    })
  ],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: availableLangs,
        defaultLang: localStorage.getItem(environment.appCode) ? JSON.parse(localStorage.getItem(environment.appCode))[LocalstorageKey.Language] : 'nl',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      })
    },
    translocoLoader
  ]
})
export class TranslocoRootModule { }
