/**
 * for online development
 */
export const environment = {
  production: false,
  apiUrl: 'https://classifact-api-develop.tccapps.nl',
  appCode: 'CFT-APP',
  authentication: {
    clientId: 'edc4830b-1bbb-471c-a7fd-27662ce147f8',
    scope: '*',
    redirectUrl: 'https://classifact-app-develop.tccapps.nl/auth/callback',
    responseType: 'code',
    codeChallengeMethod: 'S256',
    renewTimeBeforeTokenExpiresInSeconds: 20,
    postLogoutRedirectUrl: 'https://classifact-app-develop.tccapps.nl/',
    useTenants: true,
    appCallback: '/app/callback'
  }
};
